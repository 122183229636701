import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { scrollToElement } from "../../utils";
import { AboutUs, Hero, Reviews, SocialMedia } from "./components";
import { Container } from "./MainPage.styles";
import { TourGrid } from "./components/ToursGrid/ToursGrid";
import { tourTypes } from "../MainPage/components/ToursGrid/ToursGrid.data";
import FAQ from "./components/FAQ/FAQ";

const MainPage = () => {
  const [selectedTourType, setSelectedTourType] = useState(tourTypes[0].value);
  const location = useLocation();
  const elementId = location.hash.slice(1);

  React.useEffect(() => {
    scrollToElement(elementId);
  });

  return (
    <>
      <Hero setSelectedTourType={setSelectedTourType} />
      <Container>
        <TourGrid
          selectedTourType={selectedTourType}
          setSelectedTourType={setSelectedTourType}
        />
      </Container>
      <AboutUs />
      <Container>
        <Reviews />
        <FAQ />
        <SocialMedia />
      </Container>
    </>
  );
};

export default MainPage;
