import { RowImage, RowText, ToursGridMasterDetail } from "./ToursGrid.styles";

export const tourMasterDetailPrefix = "TOUR-MASTER-DETAIL";

export const TourMasterDetail = (rowData) => {
  return (
    <ToursGridMasterDetail
      id={`${tourMasterDetailPrefix}-${rowData.data.row.data.Oid}}`}
    >
      {rowData.data.row.data.TourImage?.ImageUrl != null ? (
        <RowImage
          src={rowData.data.row.data.TourImage?.ImageUrl}
          alt={rowData.data.row.data.TourImage?.ImageName}
        />
      ) : null}
      <RowText>{rowData.data.row.data.Schedule}</RowText>
    </ToursGridMasterDetail>
  );
};
