import styled from "styled-components";
import { screen } from "../../../../styles/configuration";
import frenchMountains from "../../../../assets/backgrounds/french-mountains.jpg";

export const AboutUsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  background-image: url(${frenchMountains});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
`;

export const AboutUsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px 0;
  height: 100%;
  color: white;
  z-index: -2;

  backdrop-filter: blur(15px);
  background-color: rgba(17, 138, 178, 0.7);
`;

export const Title = styled.h1`
  position: relative;

  padding: 0 15px;

  text-align: center;
  font-weight: bold;
  font-size: 38px;
  text-shadow: 2px 2px 4px rgba(66, 68, 90, 0.6);

  @media ${screen.tb} {
    font-size: 48px;
  }

  &::before {
    content: "";
    position: absolute;

    top: 50%;
    left: -5%; // left => (width% - 100) / 2

    width: 110%;

    height: 20px;

    background: linear-gradient(270deg, #ef476f 0%, #ffd166 100%);
    transform: translateY(-50%);
    z-index: -1;
  }
`;

export const Text = styled.div`
  font-size: 15px;
  width: fit-content;
  text-align: left;
  white-space: pre-line;
  padding: 0 30px;

  @media ${screen.lp} {
    width: 70%;
    font-size: 22px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
`;

export const DetailsBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: black;
  border-radius: 100px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
`;
