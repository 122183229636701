import React, { useState } from "react";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import Form, {
  SimpleItem,
  RequiredRule,
  EmailRule,
  ButtonItem,
} from "devextreme-react/form";
import { TextArea, Toast } from "devextreme-react"; // Don't remove TextArea, it actually used.

export const TourApplicationModal = ({ data }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [toastOptions, setToastOptions] = useState({ visible: false });

  const submitButtonOptions = {
    text: "Подати заявку!",
    type: "success",
    useSubmitBehavior: true,
  };

  const handleButtonClick = () => {
    setIsPopupVisible(true);
    // console.log("MODAL");
    // console.log(data);
  };

  const handlePopupHiding = () => {
    setIsPopupVisible(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = e.target.elements;
    const tourApplication = {
      Tour: data.Oid,
      FirstName: formData.FirstName.value,
      Email: formData.Email.value,
      Message: formData.Message.value,
      PhoneNumber: "+38" + formData.PhoneNumber.value,
      CreatedAt: new Date().toISOString(),
    };

    const endpoint =
      process.env.REACT_APP_API_URL + "/api/odata/XpTourApplication";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
          "ngrok-skip-browser-warning": "69420",
        },

        body: JSON.stringify(tourApplication),
      });

      if (!response.ok) {
        throw new Error(`Сталась помилка за кодом: ${response.status}`);
      }

      setIsPopupVisible(false);
      setToastOptions({
        visible: true,
        type: "success",
        message: "Заявка подана успішно!",
      });
    } catch (error) {
      console.error("Error:", error);
      setToastOptions({
        visible: true,
        type: "error",
        message: error.message,
      });
    }
  };

  const handleToastHiding = () => {
    setToastOptions({ visible: false });
  };

  return (
    <div>
      <Button
        stylingMode="contained"
        type="success"
        text="Записатись до туру!"
        onClick={handleButtonClick}
      />
      <Popup
        id={`POPUP-ID-${data.Oid}`}
        visible={isPopupVisible}
        onHiding={handlePopupHiding}
        hideOnOutsideClick={true}
        showTitle={true}
        title="Запис до туру"
        height={430}
        maxWidth={760}
      >
        <form id="FORM-WRAP-ID" onSubmit={handleFormSubmit}>
          <Form id="FORM-ID" showValidationSummary={true}>
            <SimpleItem
              dataField="FirstName"
              editorOptions={{ placeholder: "Введіть ваше ім'я" }}
              label={{ text: "Ім'я", location: "top" }} // електронна пошта
            >
              <RequiredRule message="Заповніть ім'я" />
            </SimpleItem>
            <SimpleItem
              dataField="Email"
              label={{ text: "Електронна пошта", location: "top" }}
              editorOptions={{ placeholder: "Введіть ваш e-mail" }}
            >
              <RequiredRule message="Заповніть електронну пошту!" />
              <EmailRule message="Електронна пошта має мати правильний формат! - email@domain.com" />
            </SimpleItem>
            <SimpleItem
              dataField="Message"
              editorType="dxTextArea"
              editorOptions={{
                height: 90,
                autoResizeEnabled: true,
                placeholder: "Введіть вашe повідомлення (опціонально)",
              }}
              label={{ text: "Ваше повідмолення", location: "top" }}
            />
            <SimpleItem
              dataField="PhoneNumber"
              label={{ text: "Номер телефону", location: "top" }}
              editorType="dxTextBox"
              editorOptions={{
                mask: "+380 000 00 0000",
                maskRules: { 0: /[0-9]/ },
                maskInvalidMessage:
                  "Номер телефону має мати правильний формат!",
              }}
            >
              <RequiredRule message="Заповніть номер телефону!" />
            </SimpleItem>
            <ButtonItem
              buttonOptions={submitButtonOptions}
              horizontalAlignment="left"
            />
          </Form>
        </form>
      </Popup>
      <Toast
        visible={toastOptions.visible}
        type={toastOptions.type}
        message={toastOptions.message}
        onHiding={handleToastHiding}
        displayTime={2000}
      />
    </div>
  );
};
