import styled from "styled-components";
import { screen } from "../../../../styles/configuration";

export const FAQSection = styled.section`
  padding: 30px 0;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 300;
  font-size: 38px;
  line-height: 58px;
  padding: 0 0 20px 0;
  @media ${screen.tb} {
    font-size: 48px;
  }
`;