import fireIcon from "../../../../assets/fire-icon.png";
import telegramIcon from "../../../../assets/telegram-icon.png";

export const ContentData = {
  title: "Знайдіть тур на ваш смак!",
  subtitle: "Столиці Європи, ексурсії, культура і набагато більше!",
  btn1: {
    txt: "Тури в Угорщину",
  },
  btn2: {
    txt: "Тури в Словаччину",
  },
  btn3: {
    txt: "Тури в Польщу",
  },
  btn4: {
    txt: "Тури на замовлення \\ трансфери",
  },
  btn5: {
    txt: "Тури Закарпаття",
  },
  btn6: {
    txt: "Тури до Моря",
  },
};
