import React from "react";
import { Container } from "../../pages/MainPage/MainPage.styles";
import { ContentData } from "../../pages/MainPage/components/SocialMedia/SocialMedia.data";
import {
  Block1,
  BlockTitle,
  BottomSection,
  CompanyDescr,
  ContactUsSection,
  Content,
  Data,
  FooterSection,
  PageLink,
  Row,
  RowOfColumns,
  RowOfLinks,
  Title,
} from "./Footer.styles";

const Footer = () => {
  const { companyDescription } = ContentData;
  return (
    <div>
      <ContactUsSection>
        <Container>
          <Row>
            <Block1>
              <BlockTitle>Зв'яжіться з нами</BlockTitle>
              <Content>
                <Data phone>+380 955 07 31 73</Data>
                <Data email>thevivatour@gmail.com</Data>
              </Content>
            </Block1>
          </Row>
        </Container>
      </ContactUsSection>
      <FooterSection>
        <Container>
          <RowOfColumns>
            <CompanyDescr>{companyDescription}</CompanyDescr>
            <RowOfLinks>
              <Title>Тури із Ужгорода</Title>
              <PageLink>Одноденні Тури</PageLink>
              <PageLink>Всі Тури</PageLink>
            </RowOfLinks>
            <RowOfLinks>
              <Title>Viva Tours</Title>
              <PageLink>Головна</PageLink>
              <PageLink>Про нас</PageLink>
              <PageLink>Відгуки</PageLink>
            </RowOfLinks>
          </RowOfColumns>
        </Container>
      </FooterSection>
      <BottomSection>2016 - 2023. Всі права захищені.</BottomSection>
    </div>
  );
};

export default Footer;
