import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Accordion from "devextreme-react/accordion";
import ODataStore from "devextreme/data/odata/store";
import { FAQSection, Title } from "./FAQ.styles";

export const FAQ = () => {
  const [advices, setAdvices] = useState([]);

  useEffect(() => {
    const odataStore = new ODataStore({
      url: process.env.REACT_APP_API_URL + "/api/odata/XpTravelAdvices",
      version: 4,
      key: "Oid",
      select: ["Oid", "Title", "Content"],
      beforeSend: (e) => {
        e.headers = {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
          "ngrok-skip-browser-warning": "69420",
        };
      },
    });

    odataStore
      .load()
      .then((data) => {
        setAdvices(data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <FAQSection id="faq">
      <Title dangerouslySetInnerHTML={{ __html: "FAQ / Поради" }} />
      <Accordion
        dataSource={advices}
        collapsible={true}
        multiple={true}
        itemTitleRender={(data) => data.Title}
        itemRender={(data) => (
          <div
            style={{
              padding: "15px",
              margin: "5px",
              fontSize: "14px",
            }}
          >
            {data.Content}
          </div>
        )}
      />
    </FAQSection>
  );
};

export default FAQ;
