import userAvatar from "../../../../assets/user-avatar.png";
import starIcon from "../../../../assets/star-icon.png";
import nextArrow from "../../../../assets/next.png";
import prevArrow from "../../../../assets/left-arrow.png";
import feedback1 from "../../../../assets/feedbacks/feedback_1.png";
import feedback2 from "../../../../assets/feedbacks/feedback_2.png";
import feedback3 from "../../../../assets/feedbacks/feedback_3.png";
import feedback4 from "../../../../assets/feedbacks/feedback_4.png";
import feedback5 from "../../../../assets/feedbacks/feedback_5.png";
import feedback6 from "../../../../assets/feedbacks/feedback_6.png";
import feedback7 from "../../../../assets/feedbacks/feedback_7.png";
import feedback8 from "../../../../assets/feedbacks/feedback_8.png";
import feedback9 from "../../../../assets/feedbacks/feedback_9.png";
import feedback10 from "../../../../assets/feedbacks/feedback_10.png";
import feedback11 from "../../../../assets/feedbacks/feedback_11.png";
import feedback12 from "../../../../assets/feedbacks/feedback_12.png";
import feedback13 from "../../../../assets/feedbacks/feedback_13.png";
import feedback14 from "../../../../assets/feedbacks/feedback_14.png";
import feedback15 from "../../../../assets/feedbacks/feedback_15.png";

export const ContentData = {
  title: "Відгуки",
  starIcon: { src: starIcon, alt: "star icon" },
  sliderArrows: {
    nextArr: { src: nextArrow, alt: "next slide arrow" },
    prevArr: { src: prevArrow, alt: "prev slide arrow" },
  },
  reviews: [
    {
      id: "1",
      avatar: { src: feedback1, alt: "user avatar" },
      name: "Сергій Д.",
      tourInfo: {
        name: `Екскурсія до Будапешту`,
        date: `(у серпні 2017)`,
        rate: "5.0",
      },
      title: "Спасибі за організацію поїздки в Будапешт!",
      descr:
        "До Будапешта доїхали за 3,5 години. На кордоні простояли 1:20. Разом дорога зайняла близько 5 годин. Сподобалося, що водій знає маленькі і непопулярні КПП, де майже немає черги. А не тільки Чоп і стоїмо там пів дня. У Будапешті заселилися в готелі Mango Aparthotel. Місце шикарне, самий центр, пасаж з кафе і ресторанами, руїн-барами. Трохи було галасливо, але закрили вікна, увімкнули кондишн і вночі спали. Зворотна дорога теж зайняла +- 4 години, тільки поверталися пізно вже і на кордоні взагалі не було черги - пощастило. Ще раз дякую! Скоро знову в Будапешт їду з вами!",
    },
    {
      id: "2",
      avatar: { src: feedback2, alt: "user avatar" },
      name: "Тетяна К.",
      tourInfo: {
        name: `Поїздка до Італії`,
        date: `(у жовтні 2018)`,
        rate: "4.9",
      },
      title: "Чудова поїздка!",
      descr:
        'Дон з Індіани, США: "Нещодавно я відвідав Україну і скористався послугами Віва Турс для поїздки в Італію. Компанія пішла нам назустріч розкладу, огляду визначних пам\'яток, часу для шопінгу та прогулянок містом у вільний від роботи час. Вони подбали про безпеку під час транспортування, а також про те, щоб зупинятися в місцях, де є достатньо часу для відпочинку та харчування. Я б рекомендував цю турфірму для майбутніх поїздок. Ольга, гід і водій, доброзичлива і приємна. Дуже дякую!"',
    },
    {
      id: "3",
      avatar: { src: feedback3, alt: "user avatar" },
      name: "Світлана Б.",
      tourInfo: {
        name: `Поїздка до Чорногорії`,
        date: `(у жовтні 2019)`,
        rate: "4.7",
      },
      title: "Велика подяка Олі за можливість!",
      descr:
        "Встигла з сім'єю заскочити в останній вагон разом із Viva tour до Чорногорії. Оля за кермом, як риба у воді, комфортне авто, знає дорогу, підкаже завжди і в усьому і розповість цікаві випадки з подорожей) спеціально не розглядала літак, щоб подивитися Європу зсередини. З Олею це легко) Багато, де побували✌️ Вражень маса, як і фото)) Сказати, що я мега задоволена, це мало. Я просто в захваті, що все вийшло, як ми задумали і як я хотіла) Бажаю Viva tour подальшого процвітання і успіхів 😘 До нових зустрічей!",
    },
    {
      id: "4",
      avatar: { src: feedback4, alt: "user avatar" },
      name: "Андрій Г.",
      tourInfo: {
        name: `Поїздка до Угорщини та Австрії`,
        date: `(у квітні 2019)`,
        rate: "5.0",
      },
      title: "Хочемо залишити тут свої найпозитивніші емоції!",
      descr:
        "Міста: Відень і Будапешт) Вся поїздка пройшла легко, у чудовій компанії) Хочемо подякувати Вам за хоч і невелику, але насичену історичними місцями поїздку😊 Ольга - просто чудо, вона не тільки екскурсовод, а й відмінний водій, якому починаєш довіряти з першої хвилини подорожі 😁 Екскурсії проходили чітко і лаконічно, всі найбільш знаменні і дивовижні місця ми побачили, дізналися їхнє походження 😉 І наостанок відвідали магазини, купили сувеніри і гарненько відпочили у відмінному аквапарку з мінеральною водичкою 👍 Величезне спасибі Viva Tour за чудові свята, які ми чудово провели! Будемо звертатися ще👍",
    },
    {
      id: "5",
      avatar: { src: feedback5, alt: "user avatar" },
      name: "Євгеній П.",
      tourInfo: {
        name: `Поїздка до Угорщини та Австрії`,
        date: `(у жовтні 2018)`,
        rate: "4.2",
      },
      title: "Подорожували з Viva Tour!",
      descr:
        "Будапешт+Відень+аквапарк. З огляду на бюджетність туру (€160 з людини), до планування маршруту претензій немає жодних. Обрані готелі повністю відповідали моїм уявленням про комфорт. Хотілося б більше часу приділити Відню, але з огляду на бюджет, це, швидше за все, виконати це побажання - диво). Представник фірми - Ольга - керувала бусом, супроводжувала на прогулянках. Якщо коротко, то за фактом нашого знайомства готовий довірити їй супровід будь-якої поїздки, яка стосується мене або людей, які мені дорогі.",
    },
    {
      id: "6",
      avatar: { src: feedback6, alt: "user avatar" },
      name: "Анна Г.",
      tourInfo: {
        name: `Поїздка до Угорщини та Австрії`,
        date: `(у серпні 2018)`,
        rate: "5.0",
      },
      title: "Обирайте Viva Tour - про ваші примхи подбають!",
      descr:
        "Щойно повернулися з індивідуального туру. Нам змогли організувати поїздку за півтора дня, запропонували альтернативний маршрут, щоб ми могли дістатися до місця збору за відсутності квитків 😉 Ольга дуже відповідальний супроводжуючий і прекрасний гід. Скрізь були заброньовані хороші апартаменти. У Будапешті жили в пішій доступності від набережної, а у Відні - дві станції метро від площі Марії Терезії. Якщо ви хочете подорожувати без головного болю і нервів, обирайте Віва Тур - про ваші примхи подбають!",
    },
    {
      id: "7",
      avatar: { src: feedback7, alt: "user avatar" },
      name: "Ольга C.",
      tourInfo: {
        name: `Поїздка до Італії, Монако та Франції`,
        date: `(у червні 2018)`,
        rate: "5.0",
      },
      title: "Емоції хвилею переповнюють мене!",
      descr:
        "Ми повернулися з незабутньої поїздки Венеція - Ніцца - Монако - Антіб. Вже минуло кілька днів, а емоції хвилею переповнюють мене. Дуже хочеться повернутися назад, це неймовірні враження. Спасибі велике Олі та Олені за організацію і підтримку до, під час і після туру. Я люблю Viva Tour.",
    },
    {
      id: "8",
      avatar: { src: feedback8, alt: "user avatar" },
      name: "Ліна К.",
      tourInfo: {
        name: `Поїздка до Італії, Франції та Словенії`,
        date: `(у червні 2018)`,
        rate: "4.5",
      },
      title: "Море позитивних емоцій!",
      descr:
        "Спасибі за відмінно організовану поїздку в Італію, Францію та Словенію))) море позитивних емоцій, вражень... Хочеться ще)",
    },
    {
      id: "9",
      avatar: { src: feedback9, alt: "user avatar" },
      name: "Марина М.",
      tourInfo: {
        name: `Поїздка до Угорщини та Австрії`,
        date: `(у травні 2018)`,
        rate: "4.9",
      },
      title: "Ви - найкращі!",
      descr:
        'Вчора повернулися з подорожі Угорщина + Австрія. Це було чудово! Величезне спасибі дівчаткам за чудову організацію поїздки. Дуже душевно та інформативно.Маса вражень і позитивних емоцій. А термальні басейни з аквапарком у Ньїредьгазі - це незабутньо!!! Такий шикарний релакс наприкінці подорожі! Якщо хтось сумнівається щодо термальних курортів - однозначно "так". це - круто! Окреме спасибі Ользі (водієві і гіду) за безпеку, терпіння і уважність, а також отриману інформацію під час подорожі. Ви - найкращі!!!',
    },
    {
      id: "10",
      avatar: { src: feedback10, alt: "user avatar" },
      name: "Олена Б.",
      tourInfo: {
        name: `Поїздка до Чехії та Австрії`,
        date: `(у травні 2018)`,
        rate: "5.0",
      },
      title: "Найкращий формат подорожей ever",
      descr:
        "Окреме спасибі за віденську оперу! Неймовірна Прага, Шебрунн у компанії найкращих людей. Якщо Ви плануєте невимушений відпочинок, це точно з дівчатками. Оля, спасибі за терпіння і уважність! Впевнена, що не остання спільна поїздка.",
    },
    {
      id: "11",
      avatar: { src: feedback11, alt: "user avatar" },
      name: "Євгеній К.",
      tourInfo: {
        name: `Поїздки до Австрії та Чехії`,
        date: `(у жовтні 2017 та березні 2018)`,
        rate: "5.0",
      },
      title: "Все сподобалося, хороша організація",
      descr:
        "Їздили в тур 14-16 жовтня 2017, все сподобалося, хороша організація. Оскільки компанія мандрівників невелика (8 осіб), все було гнучко в плані поселень і вільного часу. Загалом тур сподобався і наповнив сіру осінь, яскравими фарбами. На додаток ☝: Їздили в тур до Праги 8-го березня, все дуже сподобалося",
    },
    {
      id: "12",
      avatar: { src: feedback12, alt: "user avatar" },
      name: "Олена Щ.",
      tourInfo: {
        name: `Поїздка до Австрії`,
        date: `(у жовтні 2017 та березні 2018)`,
        rate: "4.3",
      },
      title: "Чудова організація, душевна і дружня атмосфера.",
      descr:
        "Дякую!!! За прекрасні вихідні у Венеції. Чудова організація, душевна і дружня атмосфера. Окреме спасибі прекрасному водієві, екскурсоводу і просто приголомшливій дівчині - Олечці, за отриману інформацію, як в історичних місцях, так і просто під час дороги))) З нетерпінням чекаємо наступних спільних поїздок. ❤",
    },
    {
      id: "13",
      avatar: { src: feedback13, alt: "user avatar" },
      name: "Ірина К.",
      tourInfo: {
        name: `Поїздка до Будапешту`,
        date: `(у січні 2018)`,
        rate: "5.0",
      },
      title: "Все дуже сподобалося!",
      descr:
        "Їздила разом із Viva Tour у Будапешт 22-24 грудня. Все дуже сподобалося! Зустріли нас одразу з поїзда на вокзалі в Мукачеві, і прямо в Будапешт! Дорогою зупинялися на невеликих заправках, де можна було комфортно сходити в туалет і смачно перекусити/випити кави. До Будапешта (і назад) дісталися швидко і спокійно, в салоні автомобіля є подушечки - приємний бонус для того, щоб поїздка була максимально комфортною. Побували на екскурсії територією Королівського замку, прогулялися до президентської резиденції, звідусіль відкривався неймовірний краєвид на Дунай, красиві мости і величну будівлю Парламенту! Відвідали різдвяні ярмарки та поринули в їхню унікальну атмосферу. Також була спланована поїздка в купальні Сечені. Хто ніколи не бував у термалах - дуже рекомендую. В останній день було організовано поїздку в передмістя Будапешта, самобутній і чисто угорський Сентендре. Разом з організаторами відвідали музей марципану і прогулялися милими вуличками... Загалом, враження від поїздки тільки позитивні! Спасибі Ользі, яка довезе, розважить, почекає і завжди підкаже) Viva Tour, так тримати!",
    },
    {
      id: "14",
      avatar: { src: feedback14, alt: "user avatar" },
      name: "Крістіна Р.",
      tourInfo: {
        name: `Поїздка до Будапешту`,
        date: `(у січні 2018)`,
        rate: "4.6",
      },
      title: "Спасибі велике організаторам!",
      descr:
        "Поїздка до Будапешта була чудовою! Раніше була в столиці Угорщини тільки проїздом, а цього разу вона мене просто зачарувала. Особливо круто, що велична архітектура поєднується в цьому місті з невисокими цінами, приблизно, як у Києві))) У купальнях Сечені теж приголомшливо, це неймовірно - купатися там, де приймали ванни 100 років тому, а особливо - в гарячій воді на свіжому повітрі взимку! У старовинному місті Сентендре в передмісті Будапешта дуже гарно, сподобалося, що нас туди привезли, а в музеї марципану я спустошила свій гаманець, бо обожнюю такі солодощі! Спасибі Олі, вона - суперводій! Апартаменти теж дуже сподобалися, а ще організатори порадили чудові заклади, де можна було смачно і недорого поїсти і випити! Спасибі велике організаторам! У лютому їду з ними до Венеції!",
    },
    {
      id: "15",
      avatar: { src: feedback15, alt: "user avatar" },
      name: "Оксана К.",
      tourInfo: {
        name: `Поїздка до Угорщини та Австрії`,
        date: `(у листопаді 2017)`,
        rate: "4.8",
      },
      title: "Обов'язково поїду з вами ще!",
      descr:
        "Будапешт - Відень - незабутній trip) багато вражень) все сподобалося! Добре організований тур, було достатньо вільного часу. Оля - дуже крутий водій 😉 Обов'язково поїду з вами ще!",
    },
  ],
};
//
