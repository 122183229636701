import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./ToursGrid.css";
import Tabs from "devextreme-react/tabs";
import DataGrid, {
  Column,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import ODataStore from "devextreme/data/odata/store";
import ArrayStore from "devextreme/data/array_store";
import { tourTypes } from "./ToursGrid.data";
import { GridWrapper, Loader } from "./ToursGrid.styles";
import { TourMasterDetail, tourMasterDetailPrefix } from "./TourMasterDetail";
import {
  ToursGridRow,
  detailsButtonIdPrefix,
} from "../ToursGridRow/ToursGridRow";

export const TourGrid = ({ selectedTourType, setSelectedTourType }) => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const odataStore = new ODataStore({
      url:
        process.env.REACT_APP_API_URL + "/api/odata/XpTour?$expand=TourImage",
      version: 4,
      key: "Oid",
      select: [
        "Oid",
        "Name",
        "StartDate",
        "EndDate",
        "Schedule",
        "TourType",
        "TourImage",
        "Description",
      ],
      beforeSend: (e) => {
        e.headers = {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
          "ngrok-skip-browser-warning": "69420",
        };
      },
    });

    odataStore
      .load()
      .then((data) => {
        setTours(data);
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch((e) => {
        // console.log(e);
        setLoading(false); // Also set loading to false on error
      });
  }, []);

  const selectedIndex = tourTypes.findIndex(
    (tourType) => tourType.value === selectedTourType
  );

  const filteredTours = tours.filter(
    (tour) => tour.TourType === selectedTourType
  );

  const customStore = new ArrayStore({
    data: filteredTours,
    key: "Oid",
  });

  const expandRow = (e) => {
    // If the caller it the button with id that contains specified prefix expand
    // To expand only on the button press
    if (
      !e.isExpanded &&
      e.event.originalEvent.currentTarget.id.includes(detailsButtonIdPrefix)
    ) {
      e.component.expandRow(e.key);
    } else if (
      // if the caller is anything else but master detail
      // (to be able to click on the master details without collapse)
      e.isExpanded &&
      !e.event.originalEvent.currentTarget.id.includes(tourMasterDetailPrefix)
    ) {
      e.component.collapseRow(e.key);
    }
  };

  return (
    <div
      id="hot-tours"
      style={{
        margin: "50px 0",
      }}
    >
      <Tabs
        dataSource={tourTypes}
        selectedIndex={selectedIndex}
        onItemClick={(item) => setSelectedTourType(item.itemData.value)}
        itemRender={({ title }) => <div>{title}</div>}
      />
      <GridWrapper>
        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            dataSource={customStore}
            showColumnLines={false}
            width="100%"
            noDataText="Тури скоро з'являться"
            dataRowRender={ToursGridRow}
            onRowClick={expandRow}
						
          >
            {/* <Scrolling rowRenderingMode="virtual"></Scrolling> */}
            <Paging defaultPageSize={5} />
            <Pager
              visible={true}
              allowedPageSizes={5}
              showPageSizeSelector={true}
              showNavigationButtons={true}
            />
            <Column caption="Фото" dataField="TourImage" allowHiding={true} />
            <Column caption="Назва туру" dataField="Name" />
            <Column
              caption="Початок"
              dataField="StartDate"
              dataType="date"
              format="yyyy-MM-dd"
							allowSorting={true}
							defaultSortOrder="desc"
            />
            <Column
              caption="Кінець"
              dataField="EndDate"
              dataType="date"
              format="yyyy-MM-dd"
            />
            <MasterDetail enabled={false} component={TourMasterDetail} />
          </DataGrid>
        )}
      </GridWrapper>
    </div>
  );
};
