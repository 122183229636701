export const scrollToElement = (elementId, offset = -100) => {
  if (elementId === "") return;

  const element = document.getElementById(elementId);

  if (!element) return;

  const top = element.getBoundingClientRect().top + window.scrollY + offset;

  window.scrollTo({
    top: top,
    behavior: "smooth",
  });
};
