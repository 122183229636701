import styled, { css } from "styled-components";
import { screen } from "../../../../styles/configuration";

export const Example = styled.div`
  margin: 50px 0;

  @media ${screen.md} {
    margin: 10px 0;
  }
`;

export const RowDataWrapper = styled.tr`
  min-height: 150px;
  max-height: 150px;
`;

export const RowData = styled.div`
  font-weight: bold;
`;

export const RowActionsWrapper = styled.tr`
  text-align: right;
  width: 100%;
`;

export const RowActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const RowImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  max-height: 120px;
`;
