import { Button } from "devextreme-react";
import {
  RowData,
  RowDataWrapper,
  RowImage,
  RowActionsWrapper,
  RowActions,
} from "./ToursGridRow.styles";
import { TourApplicationModal } from "../TourApplicationModal/TourApplicationModal";
const formatDate = new Intl.DateTimeFormat("uk-UK").format;

export const detailsButtonIdPrefix = "TOURS-GRID-DETAILS";

export const ToursGridRow = ({ data }) => {
  return (
    <>
      <RowDataWrapper>
        <td rowSpan={3}>
          {data.TourImage != null ? (
            <RowImage
              alt={data.TourImage?.Name}
              src={data.TourImage?.ImageUrl}
            />
          ) : (
            <></>
          )}
        </td>
        <td>
          <RowData>{data.Name}</RowData>
        </td>
        <td>
          <RowData>{formatDate(new Date(data.StartDate))}</RowData>
        </td>
        <td>
          <RowData>{formatDate(new Date(data.EndDate))}</RowData>
        </td>
      </RowDataWrapper>
      <RowDataWrapper>
        <td colSpan={3}>{data.Description}</td>
      </RowDataWrapper>
      <RowActionsWrapper>
        <td colSpan={3}>
          <RowActions>
            <TourApplicationModal data={data} />
            <Button
              id={`TOURS-GRID-DETAILS-${data.Oid}`}
              type="default"
              text="Деталі"
              icon="chevrondown"
            />
          </RowActions>
        </td>
      </RowActionsWrapper>
    </>
  );
};
