import React from "react";
import { scrollToElement } from "../../../../utils";
import { ContentData } from "./Hero.data";
import {
  BtnTextWrapper,
  ButtonRow,
  Container,
  LinkButton,
  PageBackground,
  Section,
  Subtitle,
  Title,
  Wrapper,
} from "./Hero.styles";
import { tourTypes } from "../ToursGrid/ToursGrid.data";

const Hero = ({ setSelectedTourType }) => {
  const { title, subtitle, btn1, btn2, btn3, btn4, btn5, btn6 } = ContentData;

  const handleClick = (tourType) => {
    setSelectedTourType(tourType);
    scrollToElement("hot-tours");
  };

  return (
    <Wrapper id="hero">
      <PageBackground>
        <Container>
          <Section>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            <ButtonRow>
              <LinkButton
                href="/#hot-tours"
                light
                onClick={() => handleClick(tourTypes[0].value)}
              >
                <BtnTextWrapper>{btn1.txt}</BtnTextWrapper>
              </LinkButton>
              <LinkButton
                href="/#hot-tours"
                dark
                onClick={() => handleClick(tourTypes[1].value)}
              >
                <BtnTextWrapper>{btn2.txt}</BtnTextWrapper>
              </LinkButton>
              <LinkButton
                href="/#hot-tours"
                green
                onClick={() => handleClick(tourTypes[2].value)}
              >
                <BtnTextWrapper>{btn3.txt}</BtnTextWrapper>
              </LinkButton>
              <LinkButton
                href="/#hot-tours"
                violet
                onClick={() => handleClick(tourTypes[3].value)}
              >
                <BtnTextWrapper>{btn4.txt}</BtnTextWrapper>
              </LinkButton>
              <LinkButton
                href="/#hot-tours"
                violet
                onClick={() => handleClick(tourTypes[4].value)}
              >
                <BtnTextWrapper>{btn5.txt}</BtnTextWrapper>
              </LinkButton>
              <LinkButton
                href="/#hot-tours"
                sea
                onClick={() => handleClick(tourTypes[5].value)}
              >
                <BtnTextWrapper>{btn6.txt}</BtnTextWrapper>
              </LinkButton>
            </ButtonRow>
          </Section>
        </Container>
      </PageBackground>
    </Wrapper>
  );
};

export default Hero;
