import telegram from "../../../../assets/telegram-logo.png";
import facebook from "../../../../assets/facebook-logo.png";
import instagram from "../../../../assets/instagram-logo.png";

export const ContentData = {
  title: "Ми в соц-мережах",
  socialMedias: [
    { id: "1", src: facebook, alt: "facebook logo" },
    { id: "2", src: instagram, alt: "instagram logo" },
    { id: "3", src: telegram, alt: "telegram logo" },
  ],
  companyDescription:
    "📍 Не потрібно шукати, з ким поїхати. Компанія вже є. Маленька, до 8 осіб. \n 📍 Привозимо, селимо, показуємо, розповідаємо, куди піти, що подивитися, де пообідати, що/скільки коштує. \n 📍 Організовуємо екскурсії з місцевими гідами. \n 📍 Ділимося лайфхаками: як заощадити на дрібницях, де смачніше, де красивіше, за що варто заплатити, а за що ні. \n 📍 Продумуємо маршрути без нічних переїздів. \n 📍 Регулярно зупиняємося, щоб випити кави, сходити в туалет і розім'яти ноги. \n 📍 Заїжджаємо на шопінг, якщо залишається час. \n 📍 Встигаємо приїхати до того, як ваш поїзд/автобус приїде до вашої точки підбору.",
};
