import infoIcon from "../../../../assets/info-icon.png";
import drivingIcon from "../../../../assets/driving-icon.png";
import bookingIcon from "../../../../assets/booking-icon.png";
import excursionIcon from "../../../../assets/excursion-icon.png";
import clockIcon from "../../../../assets/clock-icon.png";
import locationIcon from "../../../../assets/location-icon.png";
import arrowIcon from "../../../../assets/arrow-icon.png";
import Vienna from "../../../../assets/toursImages/Vienna.png";
import Budapest from "../../../../assets/toursImages/Budapest.png";
import Sentendre from "../../../../assets/toursImages/Sentendre.jpg";
import Kosice from "../../../../assets/toursImages/Kosice.jpg";
import nextArrow from "../../../../assets/next.png";
import prevArrow from "../../../../assets/left-arrow.png";
import buda1 from "../../../../assets/budapestImages/budapest1.jpg";
import buda2 from "../../../../assets/budapestImages/budapest2.png";
import buda3 from "../../../../assets/budapestImages/budapest3.webp";
import buda4 from "../../../../assets/budapestImages/budapest4.jpg";
import kosice1 from "../../../../assets/kosiceImages/kosice1.jpg";
import kosice2 from "../../../../assets/kosiceImages/kosice2.jpg";
import kosice3 from "../../../../assets/kosiceImages/kosice3.jpg";
import sentendre1 from "../../../../assets/sentendreImages/sentendre1.png";
import sentendre2 from "../../../../assets/sentendreImages/sentendre2.png";
import sentendre3 from "../../../../assets/sentendreImages/sentendre3.png";

export const ContentData = {
  title: "Найближчі Тури",
  allToursBtn: "ДИВИТИСЯ ВСІ",
  subtitle: "Комфортно, Цікаво, Якісно!",
  sliderArrows: {
    nextArr: { src: nextArrow, alt: "next slide arrow" },
    prevArr: { src: prevArrow, alt: "prev slide arrow" },
  },
  iconsList: [
    { id: "0", src: infoIcon, alt: "info icon" },
    { id: "1", src: drivingIcon, alt: "driving icon" },
    { id: "2", src: bookingIcon, alt: "booking icon" },
    { id: "3", src: excursionIcon, alt: "excursion icon" },
  ],
  tourCardIcons: {
    location: { src: locationIcon, alt: "location icon" },
    clock: { src: clockIcon, alt: "clock icon" },
    arrow: { src: arrowIcon, alt: "arrow icon" },
  },
  btnTxt: "ДЕТАЛЬНІШЕ",
  toursList: [
    {
      id: "1",
      image: { src: Budapest, alt: "Подорож до Будапешту" },
      title: "Будапешт",
      price: "2400 грн",
      status: 1,
      distance: {
        from: "Ужгород",
        to: "Будапешт",
        route: ["Ужгород", "Будапешт"],
      },
      duration: { txt: "Тривалість:", time: "1 день" },
      description:
        "Будапешт часто називають “Перлиною Дунаю” і тільки побувавши в ньому можна зрозуміти чому. Місто розкинулось на двох берегах Дунаю- правий називається Буда, лівий- Пешт. Під час подорожі ми відвідаємо обидва берега та усі основні пам'ятки.",
      startDate: new Date(2023, 2, 11),
      endDate: new Date(2023, 2, 12),
      tourProgram: [
        {
          dayDate: new Date(2023, 2, 11),
          dayProgram: [
            "4:00 — Виїзд з Ужгорода. Зустрічаємося біля готелю Закарпаття і рушаємо до Будапешту. Час в дорозі приблизно 4 години + проходження кордону (приблизно 2-3 години)",
            "10:00-11:15 — Екскурсія площею Героїв, замок Вайдахуняд",
            "11:15-12:00 — Переїзд",
            "12:00-13:30 — Екскурсія Рибальским Бастіоном та Королівським Палацем",
            "13:30-14:00 — Переїзд в центр",
            "14:00-18:30 — Вільний час в центрі, можливість самостійно відвідати Базіліку св.Іштвана, пройтись вулочками з чудовими магазинами, скуштувати угорську кухню в численних кафе та ресторанчиках",
            "18:30 — Виїзд додому. Прибуття в Ужгород приблизно в 1:00 (залежить від проходження кордону)",
          ],
        },
      ],
      serviceInPrice: {
        title: "У вартість входить:",
        list: [
          "Проїзд Opel Vivaro, 8+1 місць",
          "Проїзд до пам’яток",
          "Супровід",
          "Гід-професіонал",
        ],
      },
      serviceOutOfPrice: {
        title: "У вартість не входить",
        list: [
          "Особисте медичне страхування",
          "Вхід в музеї, аквапарки, зоопарки, тощо. (ви обираєте куди вам хочеться 😄)",
        ],
      },

      images: [buda1, buda2, buda3, buda4],
    },
    {
      id: "2",
      image: { src: Kosice, alt: "Подорож до Кошице" },
      title: "Кошице",
      price: "1000 грн",
      status: 1,
      distance: {
        from: "Ужгород",
        to: "Кошице",
        route: ["Ужгород", "Михайлівці", "Кошице"],
      },
      duration: { txt: "Тривалість:", time: "1 день" },
      description:
        "Кошице — чудове старовинне місто, яке має чудову атмосферу і велику кількість пам’яток від періоду середніх століть до нового часу. Це друге за величиною місто Словаччини, столиця Східних Карпат з багатою історичною та культурною спадщиною",
      startDate: new Date(2023, 2, 3),
      endDate: new Date(2023, 2, 3),
      tourProgram: [
        {
          dayDate: new Date(2023, 2, 3),
          dayProgram: [
            "5:30 — Виїзд з Ужгорода. Зустрічаємося біля готелю Закарпаття і рушаємо до Кошице.",
            "9:30-10:00 — Візит Супермаркету Теско в м. Міхаловце (ранкова кава, сніданок)",
            "11:00-13:00 — Екскурсія по центру м. Кошице з професійним гідом.",
            "13:00-17:30 — Вільний час в м. Кошице, можливість піднятися на колокольню храма св.Єлізавети, звідки відкривається дуже гарний, величний краєвид, відвідати торговий центр Аупарк та супермаркет Білла, скуштувати словацьку кухню та пиво в численних кафе та ресторанчиках.",
            "17:30 — Виїзд з м. Кошице",
            "18:30-19:00 — Теско в Михайлівцях",
            "19:00 — Виїзд додому, приблизний час прибуття 23:00 (залежить від перетину кордона)",
          ],
        },
      ],
      serviceInPrice: {
        title: "У вартість входить:",
        list: [
          "Проїзд Opel Vivaro, 8+1 мест",
          "Проїзд до пам’яток",
          "Супровід",
          "Гід-професіонал",
        ],
      },
      serviceOutOfPrice: {
        title: "У вартість не входить",
        list: [
          "Особисте медичне страхування",
          "Вхід в музеї, аквапарки, зоопарки, тощо. (ви обираєте куди вам хочеться 😄)",
        ],
      },

      images: [kosice1, kosice2, kosice3],
    },
    {
      id: "3",
      image: { src: Sentendre, alt: "Мальовничий Сентендре" },
      title: "Будапешт, Сентендре",
      price: "1000 грн",
      status: 0,
      distance: {
        from: "Ужгород",
        to: "Будапешт",
        route: ["Ужгород", "Будапешт", "Сентендре", "Егер"],
      },
      duration: { txt: "Тривалість:", time: "1 день" },
      description:
        "Будапешт часто називають “Перлиною Дунаю” і тільки побувавши в ньому можна зрозуміти чому. Місто розкинулось на двох берегах Дунаю- правий називається Буда, лівий- Пешт. Під час подорожі ми відвідаємо обидва берега та усі основні пам'ятки. Сентендре - чарівне угорське місто, розташоване неподалік від Будапешта. Відоме своїми мальовничими брукованими вулицями, колоритними будівлями та багатою історією, Сентендре є обов'язковим місцем для відвідування для мандрівників, які шукають унікальний та автентичний угорський досвід.",
      startDate: new Date(2023, 2, 19),
      endDate: new Date(2023, 2, 20),
      tourProgram: [
        {
          dayDate: new Date(2023, 2, 19),
          dayProgram: [
            "4:00 —  Виїзд з Ужгорода (стоянка перед готелем Закарпаття)",
            "4:30 — Заїдемо на заправку, для того, щоб ви могли обміняти гривні на форинти, випити кави.",
            "4:40-11:00 — Переїзд до Будапешта. Час у дорозі пишемо приблизно, все залежить від ситуації на кордоні. Зазвичай проїзд кордону - це близько 3х годин. По дорозі заїдемо на нормальну заправку, можна буде перекусити, випити кави, чай.",
            "11:00-12:00 — Екскурсія з професійним гідом по замку Вайдахуняд, площі Героїв.",
            "12:00-12:30 — Переїзд в Буду, по дорозі невелика автомобільна екскурсія.",
            "12:30-14:50 — Екскурсія територією Королівського Палацу та Рибальським Бастіоном (це найбільш відвідувана локація в Будапешті, там відкривається шикарний краєвид, можна зробити дуже гарні фото). Екскурсії входять у вартість поїздки.",
            "14:50-15:30 — Переїзд і поселення в готель і далі - вільний час. Це апартаменти, в кожному номері є душ, невелика кухня і холодильник, тож можна приготувати нескладні страви, перекусити. Готель розташований у центрі, тому після обіду можна піти на прогулянку центром і самостійно відвідати Базиліку св. Іштвана, Парламент та інші визначні пам'ятки. Ми живемо в центрі, в пішій доступності від знаменитих Руїн-барів, оперного театру, безлічі ресторанів і магазинів, ви самі можете вибирати, як вам провести вечір, а ми із задоволенням вам підкажемо. Ви навіть можете піти в термальні купальні Сечені (вартість близько 20 євро), це кілька станцій метро. Для українців транспорт у Будапешті безкоштовний. Або піти на чудову екскурсію на прогулянковому кораблику по Дунаю з аудіо-гідом російською мовою і безкоштовним шампанським. (приблизно 15 євро, вартість змінюється залежно від корабля і часу). На таку екскурсію найкраще потрапляти ввечері, коли вмикаються підсвічування і місто святковий вигляд. Ми підкажемо, як це зробити.",
          ],
        },
        {
          dayDate: new Date(2023, 2, 20),
          dayProgram: [
            "8:00 — Виїзд із готелю, переїзд до Сентендре. Це маленьке середньовічне місто, недалеко від Будапешта. Там постановою заборонено що-небудь змінювати, тому воно збереглося в первозданному вигляді (приблизно 13 століття). У Сентендре ми відвідаємо музей марципанів, при ньому є невеличкий магазин і можна купити їх із собою. Пройдемося цим чудовим містечком і поп'ємо кави, поїмо в ресторанчику, який не змінювався вже кілька століть.",
            "12:00-14:00 — Переїзд до Егера. В Егері буде оглядова екскурсія, ми піднімемося на фортецю (вхід близько 10 євро, але ви можете і не заходити всередину). Зверху можна зробити прекрасні фотографії. потім погуляємо містом і переїдемо в знамениту Долину Красунь. Це велика локація, де розташувалися близько 50-ти виробників угорського вина. Ви зможете відвідати кожен винний підвал, продегустувати і купити вино з собою. Там також є кілька ресторанчиків на будь-який смак, в одному з них дуже хороша угорська кухня, підкажемо 😉",
            "19:00-00:00 — Переїзд додому. Назад кордон проходить швидше, тож розраховуємо о 12 ночі бути вдома.",
          ],
        },
      ],
      serviceInPrice: {
        title: "У вартість входить:",
        list: [
          "Проїзд Opel Vivaro, 8+1 мест",
          "Проїзд до пам’яток",
          "Супровід",
          "Гід-професіонал",
        ],
      },
      serviceOutOfPrice: {
        title: "У вартість не входить",
        list: [
          "Особисте медичне страхування",
          "Вхід в музеї, аквапарки, зоопарки, тощо. (ви обираєте куди вам хочеться 😄)",
        ],
      },

      images: [sentendre1, sentendre2, sentendre3],
    },
  ],
};
