import React from "react";
import { ContentData } from "./AboutUs.data";
import {
  AboutUsSection,
  ButtonWrapper,
  DetailsBtn,
  Text,
  Title,
  AboutUsContentWrapper,
} from "./AboutUs.styles";

const AboutUs = () => {
  const { title, text } = ContentData;

  return (
    <AboutUsSection id="about-us">
      <AboutUsContentWrapper>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Text dangerouslySetInnerHTML={{ __html: text }} />
        {/* <ButtonWrapper>
          <DetailsBtn>{btnText}</DetailsBtn>
        </ButtonWrapper> */}
      </AboutUsContentWrapper>
    </AboutUsSection>
  );
};

export default AboutUs;
