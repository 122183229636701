import styled, { css } from "styled-components";
import { screen } from "../../../../styles/configuration";

export const HotToursSection = styled.div`
  margin: 50px 0;

  @media ${screen.md} {
    margin: 10px 0;
  }
`;

export const Loader = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  margin: 50px auto;
  border: 8px solid rgba(66, 68, 90, 0.25);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
`;

export const ToursGridMasterDetail = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 32px -7px rgba(66, 68, 90, 0.5);

  @media ${screen.tbSmaller} {
    margin: 10px;
  }
`;

export const RowImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 350px;
  max-height: 350px;
  border-radius: 10px;
`;

export const RowText = styled.pre`
  padding: 15px;
  width: 100%;
  margin-top: 10px;
  font-family: var(--bs-font-sans-serif);
  font-size: 14px;

  @media ${screen.tbSmaller} {
    word-wrap: unset;
    white-space: pre-wrap;
  }

  word-wrap: normal;
  white-space: break-spaces;
`;

export const GridWrapper = styled.div`
  @media ${screen.tbSmaller} {
    margin: 30px 10px;
  }

  margin: 0;
`;
