import styled, { css } from "styled-components";
import { screen } from "../../../../styles/configuration";
import allTours from "../../../../assets/all-tours-no-logo-lowres.jpg";

export const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
`;

export const PageBackground = styled.div`
  position: relative;
  background: url(${allTours}) center/cover no-repeat;
  height: 100%;
`;

export const Container = styled.section`
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: black;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  color: white;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.55);
  letter-spacing: 0.1em;
  margin: 0 0 20px 0;
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.55);
`;
export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media ${screen.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

export const BtnTextWrapper = styled.div`
  align-self: center;
  font-weight: bold;
  text-shadow: -2px 2px 2px rgba(66, 68, 90, 0.35);
  cursor: pointer;
`;

export const LinkButton = styled.a`
  gap: 5px;
  display: flex;
  margin: 20px 0 0 0;
  border-radius: 100px;
  padding: 10px 10px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: white;
  font-size: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media ${screen.tb} {
    padding: 15px 20px;
  }

  &:hover {
    color: white;
  }

  ${({ light }) =>
    light &&
    css`
      background: linear-gradient(270deg, #ef476f 0%, #ffd166 100%);
    `}
  ${({ green }) =>
    green &&
    css`
      background: linear-gradient(
        270deg,
        rgba(0, 121, 84, 1) 0%,
        rgba(6, 214, 160, 1) 100%
      );
    `}

	${({ dark }) =>
    dark &&
    css`
      background: linear-gradient(270deg, #073b4c 0%, #118ab2 69.27%);
    `}

	${({ violet }) =>
    violet &&
    css`
      background: linear-gradient(
        90deg,
        rgba(162, 54, 236, 1) 0%,
        rgba(92, 6, 150, 1) 100%
      );
    `}

    ${({ sea }) =>
    sea &&
    css`
      background: linear-gradient(
        90deg,
        rgba(54, 209, 236, 1) 0%,
        rgba(6, 116, 150, 1) 100%
      );
    `}
`;
